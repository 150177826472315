.segment-input {
  cursor: crosshair;
  position: absolute;

  line {
    fill: none;
    stroke: yellow;
    stroke-width: 2px;
  }

  rect {
    fill: yellow;
    stroke: #7f7f7f;
    stroke-width: 1px;
  }
}