.markup-progress {
  .MuiStepLabel-labelContainer span {
    font-size: x-small;
    margin-top: 8px;
    font-weight: bold;
  }

  svg {
    width: 0.8em;
    height: 0.8em;
  }
}