.markup-point {
  position: absolute;
  cursor: grab;

  .point-label {
    position: absolute;
    color: yellow;
    filter: drop-shadow(2px 4px 6px black);
    user-select: none;
    pointer-events: none;
  }

  .markup-point-center {
    position: absolute;
    border-radius: 50%;
    border: 1px solid dimgrey;
    filter: drop-shadow(2px 4px 6px black);
    pointer-events: none;
  }
}