.markup-shape {
  position: absolute;
  stroke-width: 1px;
  overflow: visible;

  text {
    font-size: 12px;
    translate: 0px -2px;
    stroke-width: 0.5px !important;
  }
}