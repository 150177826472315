.main-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.content-viewport {
  position: absolute;
  overflow: auto;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: 0 0;

  & > * {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}